import React, { useEffect, useRef, useState } from "react"
import Flickity from "react-flickity-component"
import "flickity/dist/flickity.min.css"
import { Link, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import MarkDown from "react-markdown"

//component
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import LiveChat from "../../components/Home/livechat";
import TestimonialsText from "../../components/Home/testimonials-text"

// img
import Frattire1 from "../../../images/uploads/frattire/frattire1.png"
import Frattirelight2 from "../../../images/uploads/frattire/frattirelight2.png"
import Frattiredark2 from "../../../images/uploads/frattire/frattiredark2.png"
import Frattire3 from "../../../images/uploads/frattire/frattire3.png"
import Frattire4 from "../../../images/uploads/frattire/frattire4.png"
import Frattire5 from "../../../images/uploads/frattire/frattire5.png"
import Frratiers1 from "../../../images/uploads/frattire/frratiers1.png"
import Frratiers2 from "../../../images/uploads/frattire/frratiers2.png"
import Frratiers3 from "../../../images/uploads/frattire/frratiers3.png"
import Frratiers4 from "../../../images/uploads/frattire/frratiers4.png"
import Frratiers5 from "../../../images/uploads/frattire/frratiers5.png"
import Frratiers6 from "../../../images/uploads/frattire/frratiers6.png"
import Frratiers7 from "../../../images/uploads/frattire/frratiers7.png"
import Frratiers8 from "../../../images/uploads/frattire/frratiers8.png"
import Frratiers9 from "../../../images/uploads/frattire/frratiers9.png"
import Frratiers10 from "../../../images/uploads/frattire/frratiers10.png"
import Frratiers11 from "../../../images/uploads/frattire/frratiers11.png"
import Frratiers12 from "../../../images/uploads/frattire/frratiers12.png"
import Frratiers13 from "../../../images/uploads/frattire/frratiers13.png"
import Frratiers14 from "../../../images/uploads/frattire/frratiers14.png"
import Frratiers15 from "../../../images/uploads/frattire/frratiers15.png"
import Frratiers16 from "../../../images/uploads/frattire/frratiers16.png"


import addcs1 from "../../../images/uploads/decodeup-contemporary-additional-case-study.png"
import addcs2 from "../../../images/uploads/decodeup-rexperts-additional-case-study.png"

const Frattire = props => {
  const SEOdata = props.data.pageData.edges[0].node.frontmatter?.seo_metadata
  const Schemadata = props.data.pageData.edges[0].node.frontmatter?.Schema
  const Hreflang = props.data.pageData.edges[0].node.frontmatter?.Hreflang
  const [margin, setMargin] = useState()
  const flRef = useRef()

  useEffect(() => {
    let box = document.querySelector(".container")
    let width = box.offsetWidth
    let innerw = window.innerWidth
    let paddingApplied = innerw - width

    setMargin(paddingApplied)
  })

  const options = {
    // contain: true,
    // wrapAround: true,
    // initialIndex: 2,
    // pageDots: false,
    // autoPlay: false,
    // prevNextButtons: false,
    // adaptiveHeight: true,
    lazyLoad: true,
    prevNextButtons: true,
    cellAlign: "left",
    contain: true,
    freeScroll: true,
    pageDots: false,
    percentPosition: true,
    adaptiveHeight: true,
  }

  const onMouseEnter = () => {
    flRef.current.flkty.player.play()
  }
  const onMouseLeave = () => {
    flRef.current.flkty.player.stop()
  }
  return (
    <>
      <Layout>
        <Seo
          title={SEOdata ? SEOdata.seo_title : "frattire"}
          description={SEOdata ? SEOdata.seo_description : ""}
          keywords={SEOdata ? SEOdata.seo_keyword : ""}
          image={SEOdata ? SEOdata.seo_Image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src : ''}
          single_image={SEOdata ? SEOdata.seo_Single_Image : ''}
          currentPageUrlPath={props.location.pathname}
          image_format={SEOdata ? SEOdata.image_format : ""}
          image_height={SEOdata ? SEOdata.feature_image_height : ""}
          img_width={SEOdata ? SEOdata.img_width : ""}
          facebook_url={SEOdata ? SEOdata.facebook_url : ""}
          twitter_page_username={SEOdata ? SEOdata.twitter_page_username : ""}
          twitter_image_alt_tag={SEOdata ? SEOdata.twitter_image_alt_tag : ""}
          twitter_estimated_reading_lable={SEOdata ? SEOdata.twitter_estimated_reading_lable : ""}
          twitter_estimated_reading_time={SEOdata ? SEOdata.twitter_estimated_reading_time : ""}
          page_publish_date={SEOdata ? SEOdata.page_publish_date : ""}
          page_last_modified_time={SEOdata ? SEOdata.page_last_modified_time : ""}
          sitelinks_schema={Schemadata ? Schemadata.sitelinks_schema : ""}
          breadcrumb_schema={Schemadata ? Schemadata.breadcrumb_schema : ""}
          logo_schema={Schemadata ? Schemadata.logo_schema : ""}
          organization_schema={Schemadata ? Schemadata.organization_schema : ""}
          aggregate_rating_schema={Schemadata ? Schemadata.aggregate_rating_schema : ""}
          local_business_schema={Schemadata ? Schemadata.local_business_schema : ""}
          site_navigation_schema={Schemadata ? Schemadata.site_navigation_schema : ""}
          speakable_specification_schema={Schemadata ? Schemadata.speakable_specification_schema : ""}
          hreflang1={Hreflang ? Hreflang.hreflang_1 : ""}
          hreflang2={Hreflang ? Hreflang.hreflang_2 : ""}
          hreflang3={Hreflang ? Hreflang.hreflang_3 : ""}
          hreflang4={Hreflang ? Hreflang.hreflang_4 : ""}
        />

        {/* banner  section */}
        <section className="h-full  xl:h-screen case-draft-section">
          <div className="h-full container mx-auto  ">
            <div className="pb-20 pt-1-63 md:py-2-00 lg:pt-64 h-full flex flex-col justify-center">
              <div className="mb-6-6 lg:mb-50 w-full  case-study-project-heading ">
                <h4 className="pr-5 lg:pl-0 mb-1-2 lg:mb-3-2 font-poppings-normal text-p3 lg:text-p2 text-term-primary">
                  Custom Clothing and Accessories
                </h4>
                <h1 className="mb-3 lg:mb-5 font-poppings-bold text-h2 lg:text-h0 xl:text-h1 text-term-primary">
                  Custom Clothing Design & E-commerce
                </h1>
                <p className="w-full lg:w-11/20  font-worksans-normal text-p4  md:text-h5 lg:text-p2 text-term-primary">
                  Apparel is one of the largest industries today and Frattire
                  aims to deliver high quality apparel and customer service to a
                  niche market - the Greek community. On this website customers
                  have the option to customize apparel products. They can choose
                  an item, update it with the design of their choice and then
                  admin approves the design, creates the product and ships it to
                  you.
                </p>
              </div>

              <div className="project-details-draft">
                <div className="flex flex-wrap lg:flex-nowrap">
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      CLIENT
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Timothy D.
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className="md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      COUNTRY
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Miami, USA
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className="md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      SERVICES
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Development, Maintenance and Support, UI/UX Design,
                      Product management, Hosting and Maintenance
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      TECHNOLOGIES USED
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Wordpress, JS
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      INDUSTRIES
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      E-Commerce
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Banner img section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <img
            className="w-full object-cover"
            src={Frattire1}
            alt="Frattire Decodeup Technologies"
          />
        </section>

        {/* intro section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-fra mx-auto">
            <div className="flex flex-wrap sm:flex-nowrap gap-8 md:gap-8 lg:gap-16 items-center justify-between">
              <div className="w-full sm:1/2 md:w-11/20">
                <h3 class="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  An e-commerce website that gives consumers power
                </h3>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  The main goal of the website is for site visitors to choose
                  and design apparel that makes your life easier. By providing
                  high quality products at affordable prices consumers can
                  purchase apparel at a competitive rate and the custom options
                  make for an enjoyable shopping experience.
                </p>
                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  Simple navigation, high-end designs, quality apparel,
                  attentive customer service and quick, secure delivery
                </p>
              </div>

              <div className="w-full sm:1/2 md:w-2/5">
                <img
                  className="mx-auto w-full h-full object-contain object-top fralight"
                  src={Frattirelight2}
                  alt="Frattire Decodeup Technologies"
                />
                <img
                  className="mx-auto w-full h-full object-contain object-top fradark"
                  src={Frattiredark2}
                  alt="Frattire Decodeup Technologies"
                />
              </div>
            </div>
          </div>
        </section>

        {/* intro bold section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-fra mx-auto">
            <div className="flex flex-wrap sm:flex-nowrap items-center gap-8 lg:gap-16 xl:gap-20">
              <div className="order-2 sm:order-1 w-full md:w-1/2">
                <img
                  className="mx-auto w-full h-full object-cover"
                  src={Frattire3}
                  alt="Frattire Decodeup Technologies"
                />
              </div>
              <div className="order-1 sm:order-2 w-full md:w-1/2">
                <h3 class="text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  Frattire is a fun, committed business with clearly defined
                  goals. Our client knew that this website needed a team who
                  understood the nuances of their consumer base, so they turned
                  to DecodeUp to answer the call. Of course, this didn't come
                  without a unique set of challenges. Let's see how the team
                  handled them.
                </h3>
              </div>
            </div>
          </div>
        </section>

        {/* Challenge section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-fra mx-auto">
            <div className="mb-3 md:mb-6-6 ">
              <h2 className="pr-10 font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary">
                The Challenges
              </h2>
            </div>
            <div className="flex flex-wrap sm:flex-nowrap gap-8 lg:gap-32">
              <div className="w-full md:w-1/2">
                <h3 class="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  Detailed management for the backend and frontend
                </h3>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  The website is an e-commerce tool at its core, with various
                  functions that must be maintained and designed for the
                  frontend and backend.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  Backend management is not that easy, especially when you are
                  an admin looking to create a specific experience for your
                  customers. Our clients wanted the ability to assign different
                  variations to their products, which meant the backend needed
                  easy-to-use features.
                </p>
                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  One of the goals for this website was to create an admin panel
                  that would allow individuals with admin status to search
                  inventory, track orders, and manage product details with ease.
                </p>
              </div>
              <div className="w-full md:w-1/2">
                <h3 class="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  Quantity-based preorder management
                </h3>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  Pre-orders a big part of e-commerce. For this project, the
                  client wanted a system where admins could control their
                  preorders based on quantity: for example, providing users a
                  discount on preorders for purchasing a certain number of
                  items.
                </p>
                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  After each order, customers would receive an email featuring
                  their design and a personal name and number. The final
                  approval goes through a team and then production begins a few
                  days letter by mail.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Challanges sec */}
        <section className="pt-10 mb-60 md:mb-20 xl:mb-1-40 fra-blue">
          <div className="container container-fra mx-auto">
            <img
              className="mx-auto w-full object-cover"
              src={Frattire4}
              alt="Frattire Decodeup Technologies"
            />
          </div>
        </section>

        {/* Solution sec */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-fra mx-auto">
            <div className="w-full mb-10 lg:mb-16">
              <h2 className="font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary text-center">
                Solutions
              </h2>
            </div>
            <div className="flex items-top flex-wrap lg:flex-nowrap gap-6 lg:gap-10 justify-between items-center lg:items-start xl:items-center">
              <div className="w-full lg:w-1/3 ">
                <h3 class="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  A custom solution for a custom business
                </h3>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  We found a way to keep inventory organized with endless
                  options that any admin could access.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  On the frontend of the site, we implemented e-commerce
                  features that make shopping convenient and fun for consumers.
                  Admin are able to provide end users with everything they need
                  to make informed purchases, such as color, size and design
                  options.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  Vendors can also search their products on the front end to see
                  how they are displayed. Vendors can also track orders for
                  their products and check analytics for sales.
                </p>
              </div>
              <div className="mx-auto w-full sm:w-1/3 max-w-3-52">
                <img
                  className="mx-auto w-full object-cover"
                  src={Frattire5}
                  alt="Frattire Decodeup Technologies"
                />
              </div>
              <div className="w-full sm:w-1/2 lg:w-1/3 ">
                <div className="mb-10 lg:mb-16">
                  <h3 class="mt-4 lg:mt-0 mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                    Custom refund process
                  </h3>
                  <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                    Our team developed an algorithm for payment processing that
                    allows for refunds in specific situations. The tool is not
                    depended on a plugin and can be used for future purchases.
                  </p>
                </div>
                <div className="lg:mb-16">
                  <h3 class="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                    A UI for maximum quality & maximum value
                  </h3>
                  <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                    The UI for Frattire allows their admin to do their jobs with
                    ease and a layout that's simple to navigate. Our UI
                    professionals have experience creating platforms that work
                    for the needs of your organization so you can spend more
                    time on your business
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* typeface */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-fra mx-auto">
            <div className="mb-8 lg:mb-12 flex flex-wrap sm:flex-nowrap gap-4 md:gap-8 lg:gap-16">
              <div className="w-full sm:w-2/5 max-w-480">
                <h2 className="font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary">
                  Typeface & <br className="hidden sm:block" />
                  Colors
                </h2>
              </div>
              <div className="w-ull sm:w-3/5 fratti-typo text-term-primary">
                <h4 className="mb-2 lg:mb-8 text-term-primary">Open Sans</h4>
                <h5 className="mb-2 lg:mb-6 text-term-primary opacity-70">
                  ABCDEFGHIJKLMNOPQRSTUVWXYZ
                </h5>
                <h6 className="text-term-primary opacity-70">
                  abcdefghijklmnopqrstuvwxyz
                </h6>
              </div>
            </div>
            <div className="mb-12 flex flex-nowrap fratti-color gap-2">
              <div className="p-4 lg:p-8 w-1/2 sm:w-4/5 fratti-co-1">
                <p className="text-rp1 lg:text-h3 font-poppings-bold text-white text-center sm:text-left">
                  #223456
                </p>
              </div>
              <div className="p-4 lg:p-8 w-1/2 sm:w-1/5 lg:max-w-240 fratti-co-2">
                <p className="text-rp1 lg:text-h3 font-poppings-bold text-white text-center">
                  #121212
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Result */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-cor mx-auto">
            <div className="mx-auto md:w-5/65 text-center">
              <h2 className="mb-4 lg:mb-6-6 font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary">
                The Result
              </h2>
              <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal ">
                The final result was a website that users love and Frattire can
                depend on. That's a successful project and our team is happy to
                have crafted the perfect solution for this client.
              </p>
              <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                We worked around the clock to create a clean, sleek site that's
                customizable and easy to manage.
              </p>
              <div className="mt-6 w-full">
                <h3 className="text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  When you have a problem DecodeUp is here to help you solve it.
                  It's why we come to work every day, and we'd love to help you.
                </h3>
              </div>
            </div>
          </div>
        </section>

        {/* slider section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="case-study-slider-bg">
            <div className="container container-fra mx-auto">
              <div
                id="myid"
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
              >
                <Flickity
                  className={"case-studies-carousel cstm-du-casestudy-slider"}
                  elementType={"div"}
                  options={options}
                  ref={flRef}
                >
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Frratiers1}
                    alt="Frattire Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Frratiers2}
                    alt="Frattire Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Frratiers3}
                    alt="Frattire Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Frratiers4}
                    alt="Frattire Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Frratiers5}
                    alt="Frattire Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Frratiers6}
                    alt="Frattire Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Frratiers7}
                    alt="Frattire Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Frratiers8}
                    alt="Frattire Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Frratiers9}
                    alt="Frattire Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Frratiers10}
                    alt="Frattire Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Frratiers11}
                    alt="Frattire Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Frratiers12}
                    alt="Frattire Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Frratiers13}
                    alt="Frattire Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Frratiers14}
                    alt="Frattire Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Frratiers15}
                    alt="Frattire Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Frratiers16}
                    alt="Frattire Decodeup Technologies"
                  />
                  {/* {info.screenshots_for_carousel.map(
                        ({ image: img }, ss) => (
                          <img
                            key={ss}
                            src={getSrc(img)}
                            className="cs-carousel-image"
                            alt="decodeup case study"
                          />
                        )
                      )} */}
                </Flickity>
              </div>
            </div>
          </div>
        </section>

        {/* client testimonials carousel section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-fra mx-auto">
            <div className="pr-2 pl-5-3 lg:pl-60 lg:pr-60 xl:px-90 xl:pl-1-41 xl:pr-1-41 2xl:pl-1-86 2xl:pr-1-87">
              <TestimonialsText />
            </div>
          </div>
        </section>

        {/* additional case studies section */}
        <section className="pb-50 md:pt-50 lg:pt-62 xl:pt-0 lg:pb-1-20">
          <div className="container mx-auto">
            <div className="pb-35 lg:pb-36 text-center">
              <h3 className="d-head-title text-rp1  lg:text-h3 font-poppings-bold text-term-primary">
                Additional Case Studies
              </h3>
            </div>
            <div className="h-full flex flex-wrap">
              <div className="px-4-1 pt-36 w-full sm:w-1/2 text-center bg-cyan">
                <Link
                  to="/case-study/dental-health-care-website"
                  className="h-full flex items-center justify-between flex-col text-center addi-case-links"
                >
                  <p className="pb-2-2 lg:pb-4 text-rp1 md:text-p1 text-white font-worksans-bold">
                    Dental Health Care Website
                  </p>
                  <div className="h-full xl:h-3-20 mx-auto overflow-hidden">
                    <img
                      className=" w-full h-full object-cover"
                      src={addcs1}
                      alt="Contemporary Dentistry Decodeup Technologies"
                    />
                  </div>
                </Link>
              </div>
              <div className=" pt-36 w-full sm:w-1/2 text-center bg-background-case">
                <Link
                  // to={post.fields.slug}
                  to="/case-study/real-estate-buy-sell-rent-platform"
                  className="h-full flex items-center justify-between flex-col text-center"
                >
                  <p className="pb-2-2 lg:pb-4 text-rp1 md:text-p1 text-white font-worksans-bold">
                    Real Estate Buy, Sell & Rent Platform
                  </p>
                  <div className="h-full xl:h-3-20 mx-auto overflow-hidden">
                    <img
                      className=" w-full h-full object-cover"
                      src={addcs2}
                      alt="Realty Experts Decodeup Technologies"
                    />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <LiveChat />
      </Layout>
    </>
  )
}

export default Frattire

export const pageQuery = graphql`
query frattirepagequery {
  pageData: allMarkdownRemark(
    filter: { fileAbsolutePath: { regex: "/case-studies/custom-clothing-design-ecommerce.md/" } }
  ) {
    edges {
      node {
        frontmatter {
          seo_metadata {
            seo_title
            seo_keyword
            seo_description
            image_format
            feature_image_height
            img_width
            facebook_url
            twitter_page_username
            twitter_image_alt_tag
            twitter_estimated_reading_lable
            twitter_estimated_reading_time
            page_publish_date
            page_last_modified_time
            seo_Image {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 1200)
              }
            }
            seo_Single_Image
          }
          Schema {
            sitelinks_schema
            breadcrumb_schema
            logo_schema
            organization_schema
            aggregate_rating_schema
            local_business_schema
            site_navigation_schema
            speakable_specification_schema
          }
          Hreflang {
            hreflang_1
            hreflang_2
            hreflang_3
            hreflang_4
          }
        }
      }
    }
  }     
}

`;